.resources {
    background: #f8fafc;
    color: #202730;
    font-family: var(--roboto);
    padding: 50px 0;
    overflow: hidden;
    @media screen and (min-width: 768px) {
        padding: 88px 0 72px;
    }
    h2 {
        text-align: center;
        position: relative;
        z-index: 1;
        font-family: var(--source);
        font-weight: 700;
        line-height: 1.4;
        font-size: 30px;
        letter-spacing: 0.02em;
        margin: 0 0 66px;
        --mark-indent: 0.5;
        --mark-h: 1.3;
    }
}
