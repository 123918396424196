.black {
    color: #fff;
    background: #202730;
    padding: 50px 0;
    @media screen and (min-width: 980px) {
        padding: 90px 0;
    }
    h2 {
        margin-bottom: 37px;
        text-align: center;
        @media screen and (min-width: 980px) {
            text-align: left;
        }
    }
    p {
        margin-bottom: 35px;
        @media screen and (min-width: 980px) {
            max-width: 480px;
        }
        + a {
            margin-bottom: -10px;
        }
    }
}
.list {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0;
    margin: 11px 0 0;
    @media screen and (min-width: 980px) {
        max-width: 380px;
    }
    > li {
        margin: 2px 0;
        > span {
            margin-top: 0;
            margin-right: 20px;
            flex-shrink: 0;
        }
        p {
            max-width: none;
        }
    }
}
