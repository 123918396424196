.startfree {
    margin-bottom: 70px;
}
.testimonial {
    h2 {
        max-width: 375px;
    }
    blockquote p {
        line-height: 1.5;
    }
}
