.faq {
    color: #202730;
    font-family: var(--roboto);
    padding: 50px 0;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 980px) {
        padding: 90px 0 116px;
    }
    h2 {
        text-align: center;
        font-family: var(--source);
        font-size: 34px;
        font-weight: 900;
        letter-spacing: 0.06em;
        line-height: 1.4;
        margin: 0 0 65px;
        --mark-indent: 0.5;
        --mark-h: 1.3;
    }
}
