.generate-template {
    background: #00baff url('./assets/maz.svg') top left no-repeat;
    background-size: auto 200%;
    position: relative;
    z-index: 0;
    padding: 60px 0;
    text-align: center;
    @media screen and (min-width: 980px) {
        padding: 90px 0;
    }
    h2 {
        margin-bottom: 20px;
    }
}
.video-wrapper {
    max-width: 710px;
    margin: 0 auto;
    padding: 20px 0 40px;
}
.video {
    width: 100%;
    padding-top: 56.26%;
    margin: 0 auto;
    position: relative;
}
.wistia {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 7px;
}
