.main-features {
    position: relative;
    z-index: 0;
    padding-top: 16px;
    padding-bottom: 32px;
    text-align: center;
    box-sizing: border-box;
    @media screen and (min-width: 980px) {
        text-align: left;
    }
}
.list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin-top: 57px;
    font-size: 16px;
    color: #202730;
    > li {
        flex-basis: 25%;
        min-width: 260px;
        max-width: 260px;
        margin: 0 auto;
        box-sizing: border-box;
        text-align: center;
        p {
            margin-top: 5px;
        }
    }
    svg {
        display: block;
        margin: 0 auto;
        height: 90px;
    }
    h3 {
        font-family: var(--source);
        font-weight: 700;
        line-height: 1.5;
        font-size: 22px;
        letter-spacing: normal;
        padding: 0;
        max-width: 180px;
        margin: 6px auto 0;
        position: relative;
    }
    p {
        font-weight: 500;
        line-height: 1.6;
        font-size: 16px;
        font-family: var(--roboto);
    }
    a {
        color: var(--primary-link-color);
        text-decoration: none;
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
}
.label {
    top: -35px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    text-align: center;
    > span {
        padding: 4px 12px;
        font-size: 11px;
        letter-spacing: 0;
        white-space: nowrap;
    }
}
