.optimized-emails {
    padding-top: 70px;
    overflow: hidden;
    h2 {
        line-height: 1.4;
        + p {
            font-family: var(--source);
            letter-spacing: 0.01em;
        }
    }
}
.feature {
    @media screen and (min-width: 980px) {
        margin-bottom: 80px;
        margin-top: 80px;
        > div {
            gap: 0 20px;
        }
    }
    h3 {
        font-size: 28px;
        font-weight: 900;
        line-height: 1.4;
        margin-bottom: 35px;
        text-align: center;
        @media screen and (min-width: 980px) {
            font-size: 30px;
            text-align: left;
        }
    }
    p {
        letter-spacing: 0.02em;
        line-height: 1.65;
        font-family: var(--roboto);
        font-size: 18px;
        color: #1f262f;
        margin-bottom: 30px;
    }
    ul {
        list-style: none;
        padding-left: 25px;
    }
    li {
        font-family: var(--roboto);
        font-size: 18px;
        line-height: 1.67;
        color: #1f262f;
        margin-bottom: 1em;
        position: relative;
        &::before {
            background-color: var(--primary-brand-color-400);
            border-radius: 6px;
            content: '';
            height: 6px;
            left: -25px;
            position: absolute;
            top: 0.7em;
            width: 6px;
        }
    }
    a {
        color: var(--primary-link-color);
        text-decoration: none;
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
    &:nth-child(1) {
        @media screen and (min-width: 980px) {
            margin-bottom: 0;
        }
        picture > div {
            @media screen and (min-width: 980px) {
                transform: translate(0, -66px);
            }
        }
    }
    &:nth-child(2) {
        @media screen and (min-width: 980px) {
            margin-top: 40px;
            margin-bottom: 0;
        }
        picture img {
            @media screen and (min-width: 980px) {
                transform: translate(12%, -11%);
            }
        }
    }
    &:nth-child(3) {
        @media screen and (min-width: 980px) {
            margin-top: 56px;
            margin-bottom: 40px;
        }
        picture img {
            @media screen and (min-width: 980px) {
                transform: translate(7%, -10%);
            }
        }
    }
    picture {
        display: block;
        > div {
            margin: 0 auto;
            max-width: 560px;
            @media screen and (min-width: 980px) {
                margin: auto;
            }
        }
    }
}
.subheader {
    @media screen and (min-width: 980px) {
        max-width: 580px;
        margin: 0 auto 40px;
    }
}
.features-list {
    display: grid;
    grid-gap: 20px 5px;
    align-items: unset;
    text-align: left;
    @media only screen and (min-width: 650px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        justify-content: center;
    }
    > * {
        box-sizing: border-box;
        @media only screen and (min-width: 650px) {
            padding-right: 20px;
        }
    }
    svg {
        display: block;
        margin: 20px 0;
        height: 55px;
        width: auto;
    }
    > *:nth-child(3) svg {
        max-width: 66px;
    }
    > *:nth-child(4) svg {
        max-width: 50px;
    }
    .more {
        grid-column: 1 / -1;
        margin-top: 22px;
    }
    em {
        font-family: var(--source);
        color: var(--primary-text-color-800);
        font-style: normal;
        font-size: 22px;
        font-weight: 700;
    }
    p {
        margin-top: 0;
        color: var(--primary-text-color-800);
        font-size: 16px;
    }
}
.quote {
    svg {
        transform: translateY(-10px);
    }
}
