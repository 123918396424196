.hero {
    position: relative;
    z-index: 0;
    padding: 55px 0 0;
    min-height: 600px;
    box-sizing: border-box;
    @media screen and (min-width: 980px) {
        padding-top: 82px;
    }
}
.btnwrap {
    margin-bottom: 30px;
}
.split {
    display: grid;
    @media screen and (min-width: 980px) {
        grid-template-columns: minmax(550px, 1fr) 1fr;
        grid-template-rows: 1fr;
    }
    article {
        @media screen and (min-width: 768px) {
            max-width: 900px;
            margin: 0 auto;
        }
        @media screen and (min-width: 980px) {
            padding-right: 10px;
            margin-bottom: 50px;
        }
    }
    h1 {
        font-size: 45px;
        margin-bottom: 20px;
        letter-spacing: 0.002em;
        line-height: 1.4;
        text-align: center;
        @media screen and (min-width: 768px) {
            font-size: 55px;
            text-align: left;
        }
    }
    h2 {
        font-family: var(--source);
        font-size: 22px;
        line-height: 1.65;
        font-weight: 700;
        letter-spacing: -0.005em;
        margin-bottom: 15px;
    }
    p {
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.02em;
        line-height: 1.65;
        margin: 0 0 20px;
        @media screen and (min-width: 768px) {
            max-width: 780px;
            margin: 0 auto 30px;
        }
    }
}
.picture {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 30px;
    @media screen and (min-width: 980px) {
        padding-top: 0;
    }
    > div {
        max-width: 560px;
        margin: 0 auto;
        min-height: 92vw;
        @media screen and (min-width: 600px) {
            min-height: 574px;
        }
        @media screen and (min-width: 980px) {
            position: relative;
            transform: translate(4px, -32px);
        }
    }
}
.info {
    max-width: 500px;
}
.button {
    margin-top: 30px;
}
