.beyond {
    padding: 50px 0;
    @media screen and (min-width: 980px) {
        padding: 90px 0 65px;
    }
    h2 {
        text-align: center;
        @media screen and (min-width: 980px) {
            text-align: left;
        }
    }
    article li {
        position: relative;
    }
}
.list {
    margin: 0;
    padding: 0;
    @media screen and (min-width: 980px) {
        margin: -13px auto 0;
        max-width: 415px;
    }
    li {
        margin: 0 0 24px;
        > div {
            margin-top: 20px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            margin-bottom: 42px;
        }
    }
    h3 {
        position: relative;
        margin-bottom: 17px;
    }
    p {
        max-width: none;
        line-height: 1.5;
    }
}
.label {
    top: -32px;
    left: 3px;
    position: absolute;
    text-align: center;
    > span {
        padding: 4px 12px;
        font-size: 11px;
        letter-spacing: 0;
        white-space: nowrap;
    }
}
