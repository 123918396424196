.steps {
    padding: 50px 0;
    @media screen and (min-width: 980px) {
        padding: 0 0 30px;
    }
}
.top {
    text-align: center;
    max-width: 780px;
    margin: 0 auto 56px;
    h2 {
        letter-spacing: 0;
    }
    p {
        font-family: var(--source);
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 1.55;
        margin: 0 0 30px;
    }
}
@media screen and (min-width: 980px) {
    .item1 > div {
        margin-bottom: 27px;
    }
    .item2 > div {
        margin-bottom: 75px;
    }
    .item3 > div {
        margin-bottom: 165px;
    }
    .item4 > div {
        margin-bottom: 55px;
    }
}
.lottiebox {
    max-width: 560px;
    margin: 0 auto;
    @media screen and (min-width: 980px) {
        max-width: 460px;
        padding-right: 20px;
        > div {
            width: 560px;
            margin: -7% 0 0 -11%;
        }
    }
}
