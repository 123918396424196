.promo-quote {
    margin-bottom: 70px;
}
.testimonial {
    color: #fff;
    font-family: var(--roboto);
    margin: 0 -20px;
    max-width: 1140px;
    background: #00baff url('./assets/maz.svg') center no-repeat;
    background-size: auto 100%;
    @media only screen and (min-width: 600px) {
        margin: 0 auto;
        max-width: 600px;
        border-radius: 8px;
    }
    @media only screen and (min-width: 980px) {
        display: flex;
        flex-wrap: nowrap;
        max-width: 1140px;
    }
}
.details {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 30px 20px 120px;
    text-align: center;
    margin: 0;
    min-height: 382px;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px 120px;
    }
    @media only screen and (min-width: 980px) {
        padding: 16px 100px 22px 72px;
        text-align: left;
    }
}
.title {
    font-family: var(--source);
    font-weight: 900;
    line-height: 1.25;
    font-size: 30px;
    letter-spacing: 0.02em;
    margin: 0;
    text-align: center;
    @media only screen and (min-width: 980px) {
        text-align: left;
    }
}
.text {
    font-family: var(--source);
    font-weight: 700;
    line-height: 1.1;
    font-size: 75px;
    letter-spacing: 0.02em;
    margin: 10px 0;
    position: relative;
    z-index: 0;
    mark {
        color: #ffeb00;
    }
}
.sub {
    font-family: var(--roboto);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    @media only screen and (min-width: 980px) {
        max-width: 460px;
    }
}
.image {
    padding: 100px 20px 40px;
    background: #00a2ff;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    @media only screen and (min-width: 980px) {
        border-radius: 0 8px 8px 0;
        padding: 20px 30px 20px 115px;
        display: flex;
        align-items: center;
        max-width: 350px;
    }
    @media only screen and (min-width: 1060px) {
        max-width: 436px;
        padding-right: 50px;
    }
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(calc(4px - 50%), calc(6px - 50%));
        @media only screen and (min-width: 980px) {
            top: 50%;
            left: 0;
        }
    }
}
.blockquote {
    text-align: left;
    margin: 0;
}
.cite {
    font-size: 18px;
    margin: 0 0 28px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 0.015em;
}
.author {
    margin: 0;
    padding: 0;
    p {
        margin: 0;
    }
    strong {
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    span {
        font-size: 15px;
        letter-spacing: 0.02em;
    }
}
